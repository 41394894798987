import { Suspense, StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { MargaretProvider } from '@tymate/margaret';
import GQLProvider from 'providers/GQLProvider';
import AuthProvider from 'providers/AuthProvider';
import LocaleProvider from 'providers/LocaleProvider';
import { theme, colors } from 'ui/theme';
import {
  ActivateAccount,
  Login,
  ForgetPassword,
  ResetPassword,
  CreatePassword,
} from './containers/Auth/index';
import Embed from 'containers/Embed';
import App from './App';
import '@fontsource/raleway';
import '@fontsource/inter';
import 'sanitize.css';
import 'sanitize.css/typography.css';
import 'sanitize.css/forms.css';
import AppProvider from 'providers/AppProvider';
import AppBannerProvider from 'providers/AppBannerProvider';
import { CryptrProvider } from '@cryptr/cryptr-react';
import {
  CRYPTR_CLIENT_ID,
  CRYPTR_AUDIENCE,
  CRYPTR_DEFAULT_REDIRECT_URL,
} from './constants';
import { ScrollToTop } from 'components';
import Password from 'containers/Auth/Password';
import NotFound from 'containers/Auth/NotFound';
import Terms from 'containers/Terms';

const GlobalStyles = createGlobalStyle`
  html {
    -webkit-font-smoothing: antialiased;
    body {
      color: #fff;
    }
  }
  nav li::before {
    content: initial;
  }  
  img {
  display: block;
  max-width: 100%;
  height: auto;
}
`;

const reload = () => window.location.reload();

const cryptrConfig = {
  tenant_domain: 'natif',
  client_id: CRYPTR_CLIENT_ID,
  audience: CRYPTR_AUDIENCE,
  default_locale: window.navigator.language === 'fr-FR' ? 'fr' : 'en',
  default_redirect_uri: CRYPTR_DEFAULT_REDIRECT_URL,
  cryptr_base_url: 'https://natif.authent.me',
  dedicated_server: true,
  telemetry: true,
  fixed_pkce: true,
};

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<div />}>
      <CryptrProvider {...cryptrConfig}>
        <MargaretProvider theme={theme} colors={colors}>
          <LocaleProvider>
            <AuthProvider>
              <GQLProvider>
                <AppProvider>
                  <AppBannerProvider>
                    <Router>
                      <ScrollToTop>
                        <GlobalStyles />
                        <Routes>
                          <Route path="login" element={<Login />} />
                          <Route path="terms" element={<Terms />} />
                          <Route
                            path="first-login"
                            element={<ActivateAccount />}
                          />
                          <Route path="password" element={<Password />} />
                          <Route path="not-found" element={<NotFound />} />
                          <Route
                            path="auth/activate_account"
                            element={<CreatePassword />}
                          />
                          <Route
                            path="lost-access"
                            element={<ForgetPassword />}
                          />
                          <Route
                            path="auth/reset_password"
                            element={<ResetPassword />}
                          />
                          <Route path="embed/:audioId" element={<Embed />} />
                          <Route path="*" element={<App />} />
                          <Route
                            path="/apple-app-site-association"
                            onEnter={reload}
                          />
                        </Routes>
                      </ScrollToTop>
                    </Router>
                  </AppBannerProvider>
                </AppProvider>
              </GQLProvider>
            </AuthProvider>
          </LocaleProvider>
        </MargaretProvider>
      </CryptrProvider>
    </Suspense>
  </StrictMode>,

  document.getElementById('root'),
);
