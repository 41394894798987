import { gql } from '@apollo/client';

export const GET_AUDIO = gql`
  query audio($audioId: ID!) {
    audio(id: $audioId) {
      id
      hlsUrl
      title
      description
      duration
      createdAt
      updatedAt
      position
      podcast {
        id
        kind
        title
        longDescription
        language
        authors {
          nodes {
            ... on User {
              id
              firstName
              lastName
              bio
              job
              role
              area {
                displayName
              }
              service {
                title
              }
              avatar {
                id
                url
              }
            }
            ... on External {
              id
              firstName
              lastName
              bio
              job
              avatar {
                id
                url
              }
            }
          }
        }
        image {
          url
        }
        aggregateDurationTime
        audiosCount
        audios(first: 25, order: { column: CREATED_AT, direction: ASC }) {
          edges {
            node {
              id
              duration
              title
              hlsUrl
              listening {
                listened
                duration
              }
            }
          }
        }
      }
      contributors {
        edges {
          node {
            id
            contributorable {
              ... on User {
                id
                firstName
                lastName
                bio
                job
                role
                area {
                  displayName
                }
                service {
                  title
                }
                avatar {
                  id
                  url
                }
              }
              ... on External {
                id
                firstName
                lastName
                bio
                job
                avatar {
                  id
                  url
                }
              }
            }
          }
        }
      }
      author {
        ... on User {
          id
          firstName
          lastName
          bio
          job
          role
          area {
            displayName
          }
          service {
            title
          }
          avatar {
            id
            url
          }
        }
        ... on External {
          id
          firstName
          lastName
          bio
          job
          avatar {
            id
            url
          }
        }
      }
      listening {
        duration
        id
        listened
        audible {
          ... on Audio {
            id
            duration
            title
            hlsUrl
            listening {
              listened
              duration
            }
          }
          ... on Presentation {
            id
            duration
            hlsUrl
            listening {
              listened
              duration
            }
          }
        }
      }
    }
  }
`;

export const GET_AUDIO_EMBED = gql`
  query audio($audioId: ID!) {
    audio(id: $audioId) {
      id
      hlsUrl
      title
      description
      duration
      cover(size: medium_section) {
        id
        url
      }
      podcast {
        id
        title
        kind
        image {
          url
        }
      }
    }
  }
`;

export const GET_USER_LISTENINGS = gql`
  query currentUserListenings {
    currentUser {
      id
      listenings(inProgress: true, listened: false, audibleType: Audio) {
        edges {
          node {
            id
            duration
            audible {
              ... on Audio {
                description
                id
                title
                hlsUrl
                duration
                cover(size: medium_section) {
                  id
                  url
                }
                listening {
                  id
                }
                author {
                  ... on User {
                    id
                    firstName
                    lastName
                    bio
                    job
                    role
                    discardedAt
                    area {
                      displayName
                    }
                    service {
                      title
                    }
                    avatar(size: medium_section) {
                      id
                      url
                    }
                  }
                  ... on External {
                    id
                    firstName
                    lastName
                    bio
                    job
                    avatar(size: medium_section) {
                      id
                      url
                    }
                  }
                }
                podcast {
                  id
                  longDescription
                  title
                  category {
                    id
                    slug
                  }
                  image(size: medium_section) {
                    id
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_LISTENING = gql`
  mutation updateListening($input: UpdateListeningInput!) {
    updateListening(input: $input) {
      listening {
        id
        audible {
          ... on Audio {
            description
            id
            duration
            hlsUrl
            podcast {
              id
              longDescription
              title
              category {
                id
                slug
              }
              image(size: medium_section) {
                id
                url
              }
            }
            listening {
              id
              duration
              listened
            }
          }
        }
      }
      errors {
        attribute
        message
        path
        error
      }
    }
  }
`;

export const CREATE_AUDIO = gql`
  mutation createAudio($input: CreateAudioInput!) {
    createAudio(input: $input) {
      audio {
        id
      }
      errors {
        attribute
        message
        path
        error
      }
    }
  }
`;

export const UPDATE_AUDIO = gql`
  mutation updateAudio($input: UpdateAudioInput!) {
    updateAudio(input: $input) {
      audio {
        id
      }
      errors {
        attribute
        message
        path
        error
      }
    }
  }
`;

export const DELETE_AUDIBLE = gql`
  mutation deleteAudible($input: DeleteAudibleInput!) {
    deleteAudible(input: $input) {
      clientMutationId
      errors {
        attribute
        message
        path
        error
      }
    }
  }
`;

export const GET_BO_AUDIO = gql`
  query audio($audioId: ID!) {
    audio(id: $audioId) {
      id
      hlsUrl
      title
      description
      publishedAt
      createdAt
      draft
      updatedAt
      cover {
        id
        url
      }
    }
  }
`;

export const ACCEPT_TERMS = gql`
  mutation acceptTerms {
    acceptTerms(input: { accept: true }) {
      user {
        id
      }
    }
  }
`;
