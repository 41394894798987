export { default as IcPlayBack } from './PlayBack';
export { default as IcPlayForward } from './PlayForward';
export { default as IcPlay } from './Play';
export { default as IcReplay } from './Replay';
export { default as IcPause } from './Pause';
export { default as IcSoundDecrease } from './SoundDecrease';
export { default as IcSoundIncrease } from './SoundIncrease';
export { default as IcShare } from './Share';
export { default as IcPower } from './IcPower';
export { default as IcTarget } from './Target';
