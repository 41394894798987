import { ButtonReset, Stack } from '@tymate/margaret';
import logo from 'images/logo-app.svg';
import { useTranslation } from 'react-i18next';
import { MdClear } from 'react-icons/md';
import styled from 'styled-components';
import Button from './Button';

const Main = styled(Stack).attrs()`
  background: #060d0d;
  padding: 10px;
  justify-content: space-between;
  flexgrow: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
`;

const AppIcon = styled.img`
  width: 40px;
`;

const Title = styled.div`
  font-weight: 400;
`;

const getAppLink = offer => {
  return 'https://natif.page.link/ZYZX';
};

const AppBanner = ({ onDismiss }) => {
  const { t } = useTranslation('appbanner');

  const handleClick = () => {
    window.location = getAppLink();
  };

  return (
    <Main>
      <Stack gap={1} alignY="center">
        <ButtonReset onClick={onDismiss}>
          <MdClear />
        </ButtonReset>

        <AppIcon alt="logo" src={logo} />

        <Title>{t('continue')}</Title>
      </Stack>

      <Button size="full" variant="embed" onClick={handleClick}>
        {t('open')}
      </Button>
    </Main>
  );
};

export default AppBanner;
