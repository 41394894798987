import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useApp } from 'hooks';
import { ACCEPT_TERMS } from 'api/audio';
import { Stack, Text } from '@tymate/margaret';
import { Button, CheckboxField, Layout } from 'components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const Terms = () => {
  const { t } = useTranslation(['terms', 'errors']);
  const [acceptTerms] = useMutation(ACCEPT_TERMS);
  const { refetchUser } = useApp();
  const navigate = useNavigate();

  const validateTerms = async () => {
    await acceptTerms();
    await refetchUser();
    navigate('/');
  };

  return (
    <Layout size="tiny">
      <Stack alignX="center" direction="column" gutterSize={2} size="full">
        <div>
          <Text type="h1" style={{ textAlign: 'center' }}>
            {t('title')}
          </Text>
        </div>
        <div>
          <Text
            type="body"
            fontWeight="bold"
            style={{
              textAlign: 'center',
              opacity: 0.6,
            }}
          >
            {t('info')}
          </Text>
        </div>
        <Button
          variant="outline"
          onClick={() =>
            window.open(
              'https://www.natif.io/politique-de-confidentialite-2/',
              '_blank',
            )
          }
        >
          {t('see')}
        </Button>

        <Formik
          initialValues={{ terms: false }}
          validationSchema={Yup.object().shape({
            terms: Yup.bool().required(t('errors:required')),
          })}
          onSubmit={validateTerms}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <CheckboxField
                name={'terms'}
                label={t('accept')}
                value={values?.terms}
              />
              <Button
                variant="primary"
                size="full"
                isLoading={isSubmitting}
                disabled={!values?.terms}
              >
                {t('continue')}
              </Button>
            </Form>
          )}
        </Formik>
      </Stack>
    </Layout>
  );
};

export default Terms;
