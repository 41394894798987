import { Navigate, Routes, Route } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import { useApp, useAuth } from 'hooks';
import Profile from 'containers/Profile';
import Podcast from 'containers/Podcast';
import Home from 'containers/Home';
import Category from 'containers/Category';
import WelcomeDesk from 'containers/WelcomDesk';
import Actualities from 'containers/Actualities';
import Team from 'containers/Team';
import { Stack } from '@tymate/margaret';
import StoresLink from 'components/StoresLink';
import PodcastDetail from 'containers/PodcastDetail';
import AudioPlayer from 'components/AudioPlayer';
import PlayerProvider from 'providers/PlayerProvider';
import { useCryptr } from '@cryptr/cryptr-react';
import { signInCryptr } from 'api';
import { gql, useMutation } from '@apollo/client';
import Admin from 'containers/Admin';
import AdminPodcast from 'containers/AdminPodcast';
import AdminAudio from 'containers/AdminAudio';

export const UPDATE_LAST_LOGIN = gql`
  mutation updateLastLogin($input: UpdateLastLoginInput!) {
    updateLastLogin(input: $input) {
      user {
        id
        lastLogin
      }
    }
  }
`;

const App = () => {
  const { accessToken, onUpdate } = useAuth();
  const { user } = useApp();

  const {
    getCurrentAccessToken: cryptrAccessToken,
    isAuthenticated,
    isLoading,
    logOut,
  } = useCryptr();

  const userId = user?.id;

  const [updateLastLogin] = useMutation(UPDATE_LAST_LOGIN);

  useDeepCompareEffect(() => {
    if (Boolean(userId)) {
      updateLastLogin({ variables: { input: {} } });
    }
  }, [{ userId }]);

  const handleCryptrLogin = async accessToken => {
    try {
      const { data } = await signInCryptr(accessToken);
      onUpdate(data);
    } catch (err) {}
  };

  const isAuth = isAuthenticated();

  useDeepCompareEffect(() => {
    if (isAuth) {
      handleCryptrLogin(cryptrAccessToken());
      logOut();
    }
  }, [{ isAuth }]);

  if (isLoading) {
    return null;
  }

  if (!Boolean(accessToken)) {
    return <Navigate to="/login" />;
  }

  if (!Boolean(user)) {
    return null;
  }

  if (!user.termsAccepted) {
    return <Navigate to="/terms" />;
  }

  return (
    <Stack direction="column">
      <PlayerProvider>
        <Routes>
          <Route path="team" element={<Team />} />
          <Route path="welcome_desk" element={<WelcomeDesk />} />
          <Route path="actualities/:id" element={<Actualities />} />
          <Route path="profile" element={<Profile />} />
          <Route path=":audioId" element={<Podcast />} />
          <Route path="category/:slug" element={<Category />} />
          <Route path="podcast/:id" element={<PodcastDetail />} />
          {user?.role === 'admin' && (
            <>
              <Route path="admin" element={<Admin />} />
              <Route path="admin/:id" element={<AdminPodcast />} />
              <Route path="admin/:id/newaudio" element={<AdminAudio />} />
            </>
          )}

          <Route path="*" element={<Home />} />
        </Routes>
        <StoresLink />
        <AudioPlayer />
      </PlayerProvider>
    </Stack>
  );
};

export default App;
